import {makeStyles, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {navOptions, SOCIAL_MEDIA} from "../../constants";
import STRINGS from "../../strings";

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: `3px solid ${theme.palette.primary.main}`,
    paddingTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  appName: {
    color: 'gray'
  },
  optionText: {
    color: "gray",
    marginRight: 20,
    marginBottom: 20,
    fontSize: 16,
    cursor: "pointer",
    "&:hover": {
      color: 'white',
      borderBottom: `1px solid ${theme.palette.primary.main}`
    }
  },
  optionsContainer: {
    display: 'flex', 
    flexDirection: "row",
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  footerSub: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 20
  },
  copyrightText: {
    color: 'gray',
    marginTop: 20,
    fontSize: 12
  },
  icon: {
    color: 'gray',
    marginRight: 20,
    marginBottom: 20,
    fontSize: 24,
    cursor: 'pointer',
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  socialContainer: {
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
  }

}))

function Footer() {
  const classes = useStyles();
  const history = useHistory();

  function onOptionClick(path) {
    history.push(path)
  }

  function onSocialClick(link) {
    window.location = link
  }
  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.appName}>
        {STRINGS.APP_NAME}
      </Typography>
      <div className={classes.footerSub}>
        <div className={classes.optionsContainer}>
          {navOptions.map(option => (
            <Typography key={option.text} onClick={() => onOptionClick(option.path)} className={classes.optionText}>
              {option.text}
            </Typography>
          ))}
        </div> 
        <div className={classes.socialContainer}>
          {SOCIAL_MEDIA.map(social => (
            <FontAwesomeIcon onClick={() => onSocialClick(social.link)}className={classes.icon} icon={social.icon} key={social.text}/>
          ))}
        </div>
      </div>
      <Typography className={classes.copyrightText}>
        {STRINGS.COPYRIGHT} 
      </Typography>
    
    </div>
  );
}

export default Footer;
