const STRINGS = {
  APP_NAME: "Gymnas.io",
  HERO_TITLE: "Fitness Reimagined",
  HERO_SUB: "The all encompassing fitness coaching platform.",
  LEARN_MORE: "Learn More",
  CONNECT: "CONNECT",
  TRACK: "TRACK",
  ANALYZE: "ANALYZE",
  COPYRIGHT: "All Rights Reserved @ Copyright 2020 Gymnas.io",
  BETA_TEST: " We are working hard on getting Gymnas.io out for beta testers. If you would like to be part of the beta testing phase join our waitlist!"
}

export default STRINGS;
