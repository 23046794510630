import { createMuiTheme } from "@material-ui/core"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#9A2C2C"
    },
    secondary: {
      main: "#CEB255"
    },
   type: 'dark' 
  },
  overrides: {
   MuiTypography: {
      root: {
        color: 'white'
      }
    },
   MuiButton: {
      root: {
        boxShadow: 'none'
      }
    },
  }
})

export default theme;
