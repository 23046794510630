import { BrowserRouter as Router, Route } from 'react-router-dom';
import Landing from '../../pages/Landing';
import Auth from '../../pages/Auth';
import NavBar from '../NavBar';
import Footer from '../Footer';
import ContactUs from '../../pages/ContactUs';


function AppRouter() {
  return (
    <>
    <Router>
      <>
      <Route path="/" component={NavBar} />
      <Route exact path="/" component={Landing} />
      <Route exact path="/auth" component={Auth} />
      <Route exact path="/contact" component={ContactUs} />
      <Route path="/" component={Footer} />
      </>
    </Router>
    </>
  );
}

export default AppRouter;
