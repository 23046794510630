import {Button, makeStyles, Paper, TextField, Typography, useMediaQuery} from "@material-ui/core";
import {useFormInput} from "../../hooks";
import {postEmailToWaitingList} from "../../services/WaitingListService";
import STRINGS from "../../strings";
import "./auth.css";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "transparent linear-gradient(#151515 22.69%, #1d190d) 0% 0% no-repeat padding-box",
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: 'center',
  },
  cardContainer: {
    maxWidth: 800,
    backgroundColor: 'rgb(11, 11, 11)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ matches }) => !matches && 'center',
    width: '90%',
    height: 450,
    marginTop: 20
  },
  leftContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(22, 22, 22)',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  leftContainerText: {
     marginLeft: 20,
    marginBottom: 20
  },
  leftContainerTextSub: {
     marginLeft: 20,
    marginBottom: 20,
    maxWidth: 350
  },
  rightContainer: {
    display: 'flex',
    width: ({ matches }) => matches ? '40%' : "80%",
    flexDirection: 'column',
    alignItems: 'center',
  },
  appName:{
    textAlign: 'center',
    color: 'lightgray',
    marginTop: 20,
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },
  joinWaitlist:{
    textAlign: 'center',
    color: 'lightgray',
    marginTop: 20,
  },
  input: {
    marginTop: 20,
    width: "80%",
  },
  joinNowButton: {
    marginTop: 20,
    width: "80%",
  },
  betaTestMessage: {
    marginTop: 40,
    textAlign: 'center',
    color: 'gray'
  }
}))

function Auth() {
  const matches = useMediaQuery('(min-width:800px)');
  const classes = useStyles({ matches });
  const email = useFormInput("");

  async function onJoinClick() {
    try {
      await postEmailToWaitingList(email.value);
      alert("You have officialiy joined the waitlist!")
      email.onChange({ target: { value: "" }});
    } catch(e) {
      alert(e);
    }
  }
  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>
        {matches && (

        <Paper className="auth" classes={{ root: classes.leftContainer }}>
          <Typography variant="body1" className={classes.leftContainerTextSub}>
            {STRINGS.BETA_TEST}
          </Typography> 
          <Typography variant="h5" className={classes.leftContainerText}>
            {STRINGS.HERO_TITLE}
          </Typography> 
        </Paper>
        )}
        <div className={classes.rightContainer}>
          <Typography variant="h5" className={classes.appName}>
            {STRINGS.APP_NAME}
          </Typography>
          <Typography variant="h6" className={classes.joinWaitlist}>
            Join our waitlist
          </Typography>
          <TextField {...email} variant="filled" label="Email" className={classes.input}/>
          <Button onClick={onJoinClick} variant="contained" color="primary" className={classes.joinNowButton}>
            Join now
          </Button>
          {!matches && (
            <Typography variant="body1" className={classes.betaTestMessage}>
              {STRINGS.BETA_TEST}
            </Typography> 
          )}
        </div>
      </div>
    </div>

  );
}

export default Auth
