import {Button, makeStyles, Typography, useMediaQuery} from "@material-ui/core";
import FeatureImages from "./components/FeatureImages";
import Hero from './components/Hero';
import MobileIntergration from './components/MobileIntergration';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: "column"
  },
  features: {
    alignSelf: "center",
    color: 'lightgray',
    textAlign: 'center',
    marginRight: 20,
    marginLeft: 20,
  },
  featuresSubText: {
    marginTop: 10
  },
  border: {
    alignSelf: 'center',
    width: '50%',
    marginTop: 40,
    marginBottom: 40,
    borderBottom: `3px solid ${theme.palette.primary.main}`
  }
}));

function Landing() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Hero />
      <FeatureImages />
      <Typography variant="h4" className={classes.features}>
        The features every coach needs
      </Typography>
      <Typography variant="h6" className={`${classes.features} ${classes.featuresSubText}`}>
        Coach your clients with our tools
      </Typography>
      <div className={classes.border} />
      <MobileIntergration />
    </div>
  );
}

export default Landing;
