import {Button, makeStyles, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import '../landing.css';
import STRINGS from '../../../strings';



const useStyles = makeStyles((theme) => ({
  heroText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    position: "relative",
    marginLeft: 40
  },
  heroTextSub: {
    color: "lightgray",
    fontWeight: 'bold',
    position: "relative",
    marginLeft: 40
  },
  heroTextCaption: {
    color: "lightgray",
    position: "relative",
    maxWidth: 300,
    marginLeft: 40,
    marginTop: 40
  },
  learnMoreButton: {
    alignSelf: 'flex-start',
    marginLeft: 40,
    marginTop: 20 
  }
}));


function Hero()  {
  const classes = useStyles();
  const history = useHistory();
  function onLearnMoreClick() {
    history.push("/auth")
  }
  return (
    <div className="hero">
      <Typography variant="h2" className={classes.heroText}>
        {STRINGS.APP_NAME}
      </Typography> 
      <Typography variant="h4" className={classes.heroTextSub}>
        {STRINGS.HERO_TITLE}
      </Typography> 
      <Typography variant="body1" className={classes.heroTextCaption}>
        {STRINGS.HERO_SUB}
      </Typography> 
      <Button onClick={onLearnMoreClick} variant="contained" color="primary" className={classes.learnMoreButton}>
        {STRINGS.LEARN_MORE}
      </Button>
    </div>

  );
}

export default Hero;
