import {useMediaQuery, makeStyles, Paper, Typography, TextField, Button} from "@material-ui/core";
import {useFormInput} from "../../hooks";
import {postContactUsMessage} from "../../services/ContactUsService";
import STRINGS from "../../strings";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "transparent linear-gradient(#151515 22.69%, #1d190d) 0% 0% no-repeat padding-box",
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: 'center',
  },
  cardContainer: {
    maxWidth: 500,
    backgroundColor: '#0E0E0E',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ matches }) => !matches && 'center',
    width: '90%',
    height: 450,
    marginTop: 20
  },
  leftContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#141414',
  },
  leftContainerText: {
     marginLeft: 20,
    marginTop: 20
  },
  leftContainerTextSub: {
     marginLeft: 20,
    marginBottom: 20,
    maxWidth: 350
  },
  rightContainer: {
    display: 'flex',
    width: ({ matches }) => matches ? '40%' : "80%",
    flexDirection: 'column',
  },
  appName:{
    marginLeft: 20,
    alignSelf: "flex-start",
    color: 'lightgray',
    marginTop: 20,
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },
  joinWaitlist:{
    textAlign: 'center',
    color: 'lightgray',
    marginTop: 20,
  },
  input: {
    marginTop: 20,
    width: "90%",
    marginLeft: 20
  },
  joinNowButton: {
    marginTop: 20,
    width: "90%",
    marginLeft: 20
  },
  betaTestMessage: {
    marginTop: 40,
    textAlign: 'center',
    color: 'gray'
  }
}))

function ContactUs() {
  const matches = useMediaQuery('(min-width:800px)');
  const classes = useStyles({ matches });
  const fullName = useFormInput("");
  const email = useFormInput("");
  const message = useFormInput("");

  async function onSendMessageClick() {
    try {
      await postContactUsMessage(fullName.value, email.value, message.value);
      alert("Your message has been sent! You will hear from us soon!");
      email.onChange({ target: { value: "" }});
      fullName.onChange({ target: { value: "" }});
      message.onChange({ target: { value: "" }});
    } catch(e) {
      alert(e);
    }
  } 
  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>

        <Paper classes={{ root: classes.leftContainer }}>
          <Typography variant="h5" className={classes.leftContainerText}>
            Send us a message!
          </Typography> 
          <TextField {...fullName} variant="filled" label="Full Name" className={classes.input}/>
          <TextField {...email} variant="filled" label="Email" className={classes.input}/>
          <TextField {...message} variant="filled" multiline rows={5} label="How can we help?" className={classes.input}/>
          <Button onClick={onSendMessageClick} variant="contained" color="primary" className={classes.joinNowButton}>
            Send Message
          </Button>
        </Paper>
      </div>
    </div>

  );
}

export default ContactUs;
