import {makeStyles, Paper, Typography} from "@material-ui/core";
import STRINGS from "../../../../strings";
import "./features.css";

const FEATURES = [
  {
    text: STRINGS.CONNECT,
    css: "feature-connect"
  },
  {
    text: STRINGS.TRACK,
    css: "feature-track"
  },
  {
    text: STRINGS.ANALYZE,
    css: "feature-analyze"
  }
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    alignSelf: 'center',
    justifyContent: "center",
    position: 'relative',
    bottom: 60
  },
  featureContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: 'black',
    filter: "grayscale()",
    display: "flex",
    flexDirection: 'column',
    alignItems: "flex-end",
    justifyContent: 'flex-end',
    '&:hover': {
      cursor: 'pointer',
      filter: "none"
    }
  },
  featureText: {
    fontWeight: 'bold',
    marginRight: 20,
    marginBottom: 20,
    position: 'relative'
  },
  featureParent: {
    background: 'black',
    height: 300,
    width: 300,
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 40,
    borderBottom: `5px solid ${theme.palette.primary.main}`
  }
}))

function FeatureImages() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {FEATURES.map(feature => (
        <FeatureImageContainer key={feature.text} feature={feature}/>
      ))}
    </div>
  );
}

function FeatureImageContainer({ feature }) {
  const classes = useStyles({ imageUrl: feature.imageUrl });
  return (
    <div className={classes.featureParent}>
      <Paper className={feature.css} key={feature.text} classes={{ root: classes.featureContainer }}>
        <Typography className={classes.featureText} variant="h4">
          {feature.text}
        </Typography>
      </Paper>
    </div>
  );

}

export default FeatureImages;
