import {useState} from "react";

export function useFormInput(def) {
  const [value, setValue] = useState(def);

  function onChange({ target }) {
    setValue(target.value);
  }

  return { onChange, value };
}
