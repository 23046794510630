import {makeStyles, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import { navOptions } from '../../constants.js';



const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 20,
    right: 20,
    display: 'flex',
    zIndex: 9999,
    flexDirection: 'row',
    alignItems: 'center'
  },
  optionText: {
    marginRight: 15,
    cursor: 'pointer',
    color: (props) => props.path === window.location.pathname ? "white" : "#979797",
    borderBottom: (props) => props.path === window.location.pathname ? `2px solid ${theme.palette.primary.main}` : "#979797"
  }
}));

function NavBar() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {navOptions.map(option => (
        <NavOption text={option.text} key={option.text} path={option.path}/>
      ))}
    </div>
  );
}

function NavOption({ text, path }) {
  const classes = useStyles({ path });
  const history = useHistory();

  function onOptionClick() {
    history.push(path);
  }

  return (
    <Typography onClick={onOptionClick} key={text.key} className={classes.optionText}>
      {text}
    </Typography>
  );
}

export default NavBar;
