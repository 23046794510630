import AppRouter from "./components/AppRouter";
import { MuiThemeProvider } from "@material-ui/core"
import theme from './styles/theme';


function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <AppRouter />
      </div>
    </MuiThemeProvider>
  );
}

export default App;
