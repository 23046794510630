import {Button, Fade, makeStyles, Paper, Typography, useMediaQuery} from "@material-ui/core";
import STRINGS from "../../../../strings";
import mobileMockImg from '../../../../assets/images/Mobile_Mock.png';
import "./mobile.css";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  container: {
    background: "transparent linear-gradient(#151515, #2a2411 83.61%) 0% 0% no-repeat padding-box",
    padding: 20,
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center'
  },
  topContainer: {
    display: 'flex',
    flexDirection: ({ matches }) => !matches ? 'column' : 'row',
    marginTop: ({ matches }) => !matches && 40,
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 1000,
  },
  leftTopContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleSub: {
    color: theme.palette.primary.main,
  },
  title: {
    color: 'lightgray',
    fontWeight: 'bold'
  },
  titleCaption: {
    color: 'lightgray',
    marginTop: 20,
    maxWidth: 400
  },
  learnMoreButton: {
    alignSelf: 'flex-start',
    marginTop: 20
  },
  mobileImg: {
    marginTop: ({ matches }) => !matches &&  40
  },
  bottomContainerText: {
    position: 'relative',
    marginLeft: 40,
    color: 'lightgray',
  },
  bottomContainer: {
    marginTop: 40,
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: 1000,
    width: '90%',
    display: 'flex',
    flexDirection: "column",
    padding: 20,
    backgroundColor: 'rgb(17, 17, 17)',
  },
  bottomContainerSub: {
    maxWidth: 600,
    marginTop: 20
  },
  bottomContainerTitle: {
    fontWeight: 'bold',
    marginTop: 20
  },
  joinWaitListContainer: {
    alignSelf: "center",
    marginLeft: 20,
    display: 'flex',
    flexDirection: "column",
    marginTop: 40,
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  joinButton: {
    marginTop: 20,
    marginBottom: 20,
    width: 200
  }
}));

function MobileIntergration() {
  const matches = useMediaQuery('(min-width:900px)');
  const classes = useStyles({ matches });
  const history = useHistory();

  function handleJoinNow() {
    history.push("/auth");
  }
  return (
    <div className={classes.container}>
      <Fade in timeout={1000}>
      <div className={classes.topContainer}>
        <div className={classes.leftTopContainer}>
          <Typography variant="caption" className={classes.titleSub}>
            Complete Intergration
          </Typography>
          <Typography variant="h5" className={classes.title}>
            Full intergration with fitness wearables.
          </Typography>
          <Typography variant="body1" className={classes.titleCaption}>
            Your clients will never miss a beat and you will be able to track their progress throught the day.
          </Typography>
          <Button variant="contained" color="primary" className={classes.learnMoreButton}>
            {STRINGS.LEARN_MORE}
          </Button>
        </div>
        <img alt="Mobile" src={mobileMockImg} height={matches ? 500 : 350} className={classes.mobileImg}/>
      </div>
      </Fade>
      <Paper className="mobile" classes={{ root: classes.bottomContainer }}>
        <Typography className={`${classes.bottomContainerText} ${classes.bottomContainerTitle}`} variant="h4">
          What is Gymnas.io?
        </Typography> 
        <Typography className={`${classes.bottomContainerText} ${classes.bottomContainerSub}`} variant="h6">
          Gymnas.io is a platfrom that allows personal trainers and coaches to manage their clients by creating customisable training and nutrition programmes, whilst tracking their progress and achievements.
        </Typography> 
        <div className={classes.joinWaitListContainer}>
          <Typography style={{ textAlign: 'center',}} variant="h3">
            Join our waitlist
          </Typography>
          <Button onClick={handleJoinNow} className={classes.joinButton} variant="contained" color="primary">
            Join Now
          </Button> 
        </div>
      </Paper>
    </div>
  );
}

export default MobileIntergration;
