import {faFacebook, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";

export const navOptions = [
  {
    text: "Home",
    path: "/"
  },
  {
    text: "Login",
    path: "/auth"
  },
  {
    text: "Contact Us",
    path: "/contact"
  }
];


export const SOCIAL_MEDIA = [
  {
    text: "Twiter",
    icon: faTwitter,
    link: "https://twitter.com/IoGymnas",
  },
  {
    text: "Facebook",
    icon: faFacebook,
    link: "https://www.facebook.com/Gymnasio-104478574867520"
  },
  {
    text: "Instagram",
    icon: faInstagram,
    link: "https://www.instagram.com/gymnas.io/"
  }
]
