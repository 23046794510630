import axios from 'axios';
import {apiEndpoint} from '../../config';


export async function postContactUsMessage(fullName, email, message) {
  if (!fullName || !email || !message) {
    throw "You must provide all the information"
  }

  try {
    const url = `${apiEndpoint}/contact-us`;
    await axios.post(url, { fullName, email, message });
    return;
  } catch(e) {
    throw e.response.data;
  }
}
