import axios from 'axios';
import { apiEndpoint } from '../../config';



export async function postEmailToWaitingList(email) {
  if (!email) throw "No email provided";

  try {
    await axios.post(`${apiEndpoint}/waiting-list`, { email });
    return true;
  } catch(e) {
    throw e.response.data;
  }

}
